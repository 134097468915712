import { Cmscta, CmsComponent } from '@Core/@types/skyway'

export interface State {
  cmsComponents?: CmsComponent[]
  Cta?: undefined | Cmscta
}

export const defaultState = (): State => ({
  cmsComponents: undefined,
  Cta: undefined,
})

const state = defaultState

export default state
