import { ActionTree, ActionContext } from 'vuex'
import { Package, DateRangeInput } from '@Core/@types/skyway'
import { RootState } from '@Core/store/types'
import { State } from './state'
import { types } from './types'

const actions: ActionTree<State, RootState> = {
  async getFixedPackages(
    context: ActionContext<State, RootState>,
    range: DateRangeInput
  ): Promise<Package[]> {
    const data = await this.$packages.getPackages('fixed', range)

    context.commit(types.SET_PACKAGES, data.getPackages)

    return data.getPackages
  },
  async getPackageByRef(
    context: ActionContext<State, RootState>,
    package_ref: string
  ): Promise<Package> {
    const data = await this.$packages.getPackageByRef(package_ref)

    context.commit(types.SET_PACKAGE, data.getPackageByRef)

    return data.getPackageByRef
  },
}

export default actions
