import { CmsArchive, CmsPostSummary } from '@Core/@types/skyway'

export interface State {
  posts: CmsPostSummary[]
  currentPage: number
  totalEntries?: number
  entriesPerPage?: number
}

export const defaultState = (): State => ({
  posts: [],
  currentPage: 1,
  totalEntries: undefined,
  entriesPerPage: undefined,
})

const state = defaultState

export default state
