import BaseService from '@Core/services/baseService'
import * as packages from '@Packages/api/queries/packages.gql'
import { Package, DateRangeInput } from '@Core/@types/skyway'

export default class PackagesService extends BaseService {
  public async getPackages(
    type: string = 'fixed',
    range: DateRangeInput
  ): Promise<{ getPackages: Package[] }> {
    const response = await this.client.query({
      query: packages['getPackages'],
      variables: {
        type,
        range,
      },
    })

    return this.respond(response)
  }

  public async getPackageByRef(
    package_ref: string
  ): Promise<{ getPackageByRef: Package }> {
    const response = await this.client.query({
      query: packages['getPackageByRef'],
      variables: {
        package_ref,
      },
    })

    return this.respond(response)
  }
}
