



















import { Component, Vue } from 'nuxt-property-decorator'
import HeaderPart from '@Core/components/parts/header/HeaderPart.vue'
import FooterPart from '@Core/components/parts/footer/FooterPart.vue'
import ResizeObserver from '@UI/components/resize-oberserver/ResizeObserver.vue'

@Component({
  components: {
    HeaderPart,
    FooterPart,
    ResizeObserver,
  },
})
export default class LayoutHome extends Vue {
  protected hasMounted: boolean = false

  head() {
    return {
      titleTemplate: '%s | Roundhouse',
    }
  }

  mounted() {
    this.hasMounted = true
  }
}
