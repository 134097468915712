import { Package } from '@Core/@types/skyway'

export interface State {
  packages: Package[]
  package?: Package
}

const state = (): State => ({
  packages: [],
  package: undefined,
})

export default state
