import { GetShortCode } from './index'
import parser from './index'

const Opening = (line: string) => {
  const shortcode = GetShortCode(line)
  const { params } = shortcode

  return params.day && params.hours
    ? `<div class="layout-grid">
  <div class="grid-item text text--bold">${params.day}</div>
  <div class="grid-item text">${params.hours}</div>
  </div>`
    : ''
}

export default Opening
