import { map } from 'lodash'
import Button from './Button'
import Opening from './Opening'
import { ShortcodeParser } from './ShortcodeParser'
import parse from 'html-dom-parser'
import {
  Comment,
  DomHandlerOptions,
  Element,
  ProcessingInstruction,
  Text,
} from 'domhandler'

interface ShortcodeFunction {
  (line: string): string
}

type ShortCodeParams = {
  [k: string]: string
}

type ShortCode = {
  shortcode: string | null
  content: string
  params: ShortCodeParams
}

export const decode = (str: string, options?: DomHandlerOptions): string => {
  const parsed: Array<Comment | Element | ProcessingInstruction | Text> = parse(
    str,
    options
  )

  try {
    if (parsed && parsed[0]) {
      if (typeof parsed[0] === 'object' && parsed[0].data) {
        return parsed[0].data.toString()
      }
    }
  } catch (err_) {
    return ''
  }

  return ''
}

/**
 * Gets a shortcode from the string of content
 * and parses it into a usable object
 */
export const GetShortCode = (line: string): ShortCode => {
  const shortcodeObj = {
    shortcode: '',
    content: '',
    params: {},
  }

  // get shortcode from line (gets everything in between the first set of square brackets)
  const match = line ? line.match(/\[([^\]]*)\]/g) : false

  const shortcode = match && match[0] ? match[0].toString() : ''

  shortcodeObj.shortcode = decode(shortcode)

  // get array of shortcode parameters
  const params = shortcodeObj.shortcode
    ? shortcodeObj.shortcode.match(
        /[\w-]+=['"“”″„”«»][^'"“”″„”«»]*['"“”″„”«»]/g
      )
    : {}

  // get content between the opening and closing shortcode tags
  const content = line.match(/\](.*?)\[/g)
  if (content) {
    shortcodeObj.content = content.toString().slice(1, -1)
  }

  // turn params into key/value pairs
  map(params, (param: string) => {
    const arr = param.split('=')
    shortcodeObj.params[arr[0]] = arr[1].slice(1, -1).replace("'", "\\'")
  })

  return shortcodeObj
}

/**
 * We compose a list of accepted shortcodes
 * the key should correspond to the name used
 * in the text editor and the function is the
 * handler
 */
const Shortcodes: { [key: string]: ShortcodeFunction } = {
  button: Button,
  ov_button: Button,
  opening: Opening,
}

/**
 * Export an instance of our parser
 */
const parser = new ShortcodeParser(Shortcodes)

export default parser
