import Vue from 'vue'
import { MutationTree } from 'vuex'
import { CmsMenu } from '@Core/@types/skyway'
import { types } from './types'
import { State, defaultState } from './state'

const mutations: MutationTree<State> = {
  [types.SET_MENU](state: State, payload: CmsMenu): void {
    if (
      state.menus &&
      !state.menus.find(
        (menu) => payload.slug && menu.slug && menu.slug === payload.slug
      )
    ) {
      state.menus.push(payload)
      Vue.set(state, 'menus', state.menus)
      if (payload && payload.slug) {
        Vue.set(state.lastFetch, payload.slug, Math.floor(Date.now() / 1000))
      }
    }
  },
  [types.RESET](state: State): void {
    Object.assign(state, defaultState())
  },
}

export default mutations
