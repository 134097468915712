export const Pages = {
  Index: () => import('@Marketing/pages/index').then((m) => m.default || m),
  PingPage: () => import('@Marketing/pages/ping').then((m) => m.default || m),
  All: () => import('@Marketing/pages/_').then((m) => m.default || m),
  ReadWatchListen: () =>
    import('@Marketing/pages/read-watch-listen.vue').then(
      (m) => m.default || m
    ),
  ReadWatchListenLanding: () =>
    import('@Marketing/pages/read-watch-listen/index.vue').then(
      (m) => m.default || m
    ),
  ReadWatchListenChild: () =>
    import('@Marketing/pages/read-watch-listen/_.vue').then(
      (m) => m.default || m
    ),
  ReadArticles: () =>
    import('@Marketing/pages/read/_.vue').then((m) => m.default || m),
  ReadPageWithForm: () =>
    import('@Marketing/pages/read/form.vue').then((m) => m.default || m),
  WatchArticles: () =>
    import('@Marketing/pages/watch/_.vue').then((m) => m.default || m),
  ListenArticles: () =>
    import('@Marketing/pages/listen/_.vue').then((m) => m.default || m),
  AnnualReviewPage: () =>
    import('@Marketing/pages/annual-review/index.vue').then(
      (m) => m.default || m
    ),
  MembershipIndexPage: () =>
    import('@Marketing/pages/support-us/membership.vue').then(
      (m) => m.default || m
    ),
  CreativeStudioMembershipPage: () =>
    import(
      '@Marketing/pages/young-creatives-11-30/creative-studios-membership.vue'
    ).then((m) => m.default || m),
  VenueHirePage: () =>
    import('@Marketing/pages/venue-hire.vue').then((m) => m.default || m),
  OurWorkPage: () =>
    import('@Marketing/pages/our-work.vue').then((m) => m.default || m),
  CommunityEngagementPage: () =>
    import('@Marketing/pages/community-engagement/index.vue').then(
      (m) => m.default || m
    ),
  NewsletterSignupPage: () =>
    import('@Marketing/pages/newsletter-sign-up.vue').then(
      (m) => m.default || m
    ),
}
