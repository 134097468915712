import { GetterTree } from 'vuex'
import { State } from './state'
import { RootState } from '@Core/store/types'
import { CmsLayout, CmsComponent } from '@Core/@types/skyway'

const getters: GetterTree<State, RootState> = {
  layoutByName:
    (state: State) =>
    (name: string): CmsLayout | undefined => {
      const layout = state.layouts.find(
        (layout) => layout && layout.name === name
      )

      return layout
    },
  component:
    (state: State, getters) =>
    (component: string, layout: string): CmsComponent | undefined => {
      const pageLayout = getters.layoutByName(state)(layout)

      return (
        pageLayout && pageLayout.components.find((c) => c.name === component)
      )
    },
  allComponentsByName: (state: State) => (name: string) => {
    const output: CmsComponent[] = []

    for (const l of state.layouts) {
      if (l.components && Array.isArray(l.components)) {
        // @ts-ignore
        output.push(...l.components)
      }
    }

    return output.filter((c) => c && c.name === name)
  },
}

export default getters
