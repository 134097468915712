import { GetShortCode } from './index'

const Button = (line: string) => {
  const shortcode = GetShortCode(line)
  const { params } = shortcode
  let { href, label, type } = params

  if (!['primary', 'secondary', 'tertiary'].includes(String(type))) {
    type = 'primary'
  }

  if (href && label) {
    return href.includes('http')
      ? `<a href="${href}" class="button  button--${
          type || 'primary'
        }""><span class="button__content">${label}</span></a>`
      : `<nuxt-link to="${href}" class="button button--${
          type || 'primary'
        }"><span class="button__title text text--small">${label}</span></nuxt-link>`
  } else {
    return ''
  }
}

export default Button
