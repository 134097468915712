import { Pages } from '@RoomBooking/config/imports'

export const ROUTE_NAME = {
  INDEX: 'book-a-studio',
  SINGLE: 'book-a-studio-id',
  ENQUIRY: 'book-a-stuio-enquiry',
}

export default [
  {
    path: '/book-a-studio',
    component: Pages.BookRoomListPage,
    name: ROUTE_NAME.INDEX,
  },
  {
    path: '/book-a-studio/enquiry/:id?',
    component: Pages.BookRoomEnquiry,
    name: ROUTE_NAME.ENQUIRY,
  },
  {
    path: '/book-a-studio/:id?/:year?/:month?',
    component: Pages.BookRoomSingle,
    name: ROUTE_NAME.SINGLE,
  },
]
