import { ActionTree, ActionContext } from 'vuex'
import { RootState } from '@Core/store/types'
import { CmsArchive, CmsArchiveInput, CmsSearch } from '@Core/@types/skyway'
import { State } from './state'
import { types } from './types'

const actions: ActionTree<State, RootState> = {
  async getArchive(
    context: ActionContext<State, RootState>,
    args: {
      type: string
      params: CmsArchiveInput
      clear: boolean
      full: boolean
    }
  ): Promise<CmsArchive | false> {
    const data = args.full
      ? await this.$wp.getArchiveFull(args.type, args.params)
      : await this.$wp.getArchive(args.type, args.params)

    if (data && data.getCMSArchive) {
      /**
       * Clear any old posts first
       */
      if (args.clear) {
        context.commit(types.RESET)
      }

      context.commit(types.SET_ARCHIVE, data.getCMSArchive)
      context.commit(types.SET_PAGINATION, data.getCMSArchive.pagination)

      return data.getCMSArchive
    }

    return false
  },
  async searchCmsArchive(
    context: ActionContext<State, RootState>,
    args: {
      type: string
      params: CmsArchiveInput
      clear: boolean
      full: boolean
    }
  ): Promise<CmsArchive> {
    const data = await this.$wp.searchCmsArchive(args.type, args.params)

    return data.getCMSArchive
  },
  async searchCMS(
    context: ActionContext<State, RootState>,
    args: { term: string; type: string }
  ): Promise<CmsSearch> {
    const data = await this.$wp.searchCMS(args.term, args.type)

    return data.searchCMS
  },
  resetArchive(context: ActionContext<State, RootState>) {
    context.commit(types.RESET)
  },
}

export default actions
