










import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class ColumnBlock extends Vue {
  @Prop({ type: String, required: true }) title!: string
}
