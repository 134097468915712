import BaseService from '@Core/services/baseService'
import {
  CmsArchiveInput,
  CmsEmailInput,
  CmsEmailResponse,
  CmsTaxonomyInput,
  CmsArchiveParams,
} from '@Core/@types/skyway'
import * as wp from '@Marketing/api/queries/wp.gql'

export interface IWPSkywayService {
  getPage: (slug: string) => Promise<any>
  getMenu: (slug: string) => Promise<any>
  getArchive: (slug: string, params: CmsArchiveInput) => Promise<any>
  getArchiveFull: (type: string, params: CmsArchiveInput) => Promise<any>
  searchCmsArchive: (type: string, params: CmsArchiveInput) => Promise<any>
  getCmsTaxonomy: (
    type: string,
    params: CmsTaxonomyInput,
    archive_params: CmsArchiveParams
  ) => Promise<any>
  sendCmsEmail: (emailInput: CmsEmailInput, recaptcha: string) => Promise<any>
  searchCMS: (term: string, type: string) => Promise<any>
  getCMSOptionsComponents: (components: string) => Promise<any>
}

export default class WpSkywayService
  extends BaseService
  implements IWPSkywayService
{
  async getPage(slug: string) {
    const response = await this.client.query({
      query: wp.GetCMSPage,
      variables: {
        slug,
      },
    })

    return this.respond(response)
  }

  async getMenu(slug: string) {
    const response = await this.client.query({
      query: wp.GetCMSMenu,
      variables: {
        slug,
      },
    })

    return this.respond(response)
  }

  async getArchive(type: string, params: CmsArchiveInput) {
    const response = await this.client.query({
      query: wp.GetCMSArchive,
      variables: {
        type,
        params,
      },
    })

    return this.respond(response)
  }

  async getArchiveFull(type: string, params: CmsArchiveInput) {
    const response = await this.client.query({
      query: wp.GetCMSArchiveFull,
      variables: {
        type,
        params,
      },
    })

    return this.respond(response)
  }

  async searchCmsArchive(type: string, params: CmsArchiveInput) {
    const response = await this.client.query({
      query: wp.SearchCMSArchive,
      variables: {
        type,
        params,
      },
    })
    return this.respond(response)
  }

  async getCmsTaxonomy(
    type: string,
    params: CmsTaxonomyInput,
    archive_params: CmsArchiveParams
  ) {
    const response = await this.client.query({
      query: wp.getCMSTaxonomy,
      variables: {
        type,
        params,
        archive_params,
      },
    })

    return this.respond(response)
  }

  async sendCmsEmail(
    emailInput: CmsEmailInput,
    recaptcha: string
  ): Promise<CmsEmailResponse> {
    const response = await this.client.mutate({
      mutation: wp.sendCMSEmail,
      variables: {
        params: emailInput,
        recaptcha,
      },
    })

    return this.respond(response)
  }

  async searchCMS(term: string, type: string): Promise<any> {
    const response = await this.client.query({
      query: wp.searchCMS,
      variables: {
        term,
        type,
      },
    })

    return this.respond(response)
  }

  async getCMSOptionsComponents(components: string): Promise<any> {
    const response = await this.client.query({
      query: wp.getCMSOptionsComponents,
      variables: {
        components,
      },
    })

    return this.respond(response)
  }
}
