


















import { Component, Vue } from 'nuxt-property-decorator'
import ResizeObserver from '@UI/components/resize-oberserver/ResizeObserver.vue'
import HeaderPart from '@Core/components/parts/header/HeaderPart.vue'

@Component({
  components: {
    ResizeObserver,
    HeaderPart,
  },
})
export default class AnnualReview extends Vue {
  head() {
    return {
      titleTemplate: '%s | Roundhouse',
    }
  }
}
