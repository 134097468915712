import { ActionTree, ActionContext } from 'vuex'
import { RootState } from '@Core/store/types'

import { State } from './state'
import { types } from './types'

import { CmsComponent } from '@Core/@types/skyway'

const actions: ActionTree<State, RootState> = {
  async getCMSOptionsComponents(
    context: ActionContext<State, RootState>,
    component: string
  ): Promise<CmsComponent[]> {
    const data = await this.$wp.getCMSOptionsComponents(component)

    if (data && data.getCMSOptionsComponents) {
      context.commit(types.SET_OPTIONS_COMPONENTS, data.getCMSOptionsComponents)
    }

    return data && data.getCMSOptionsComponents
  },
}

export default actions
