import { GetterTree } from 'vuex'
import { RootState } from '@Core/store/types'
import { State } from './state'
import { Package, Instance } from '@Core/@types/skyway'

const getters: GetterTree<State, RootState> = {
  /**
   * Check if we have a package in state that contains the
   * instance. Use this to return the Package details to add
   * to cart
   */
  packageByEventInstance:
    (state: State) =>
    (instance: Instance): false | Package => {
      const pckg = state.packages.find(
        (pck) =>
          pck.instances &&
          pck.instances.filter(
            (pckInst) =>
              pckInst && instance.instance_ref === pckInst.instance_ref
          ).length > 0
      )

      return pckg || false
    },
}

export default getters
