import {
  CmsPage,
  CmsLayout,
  Maybe,
  CmsFeaturedImage,
} from '@Core/@types/skyway'

export interface State {
  page?: CmsPage
  layouts: CmsLayout[]
  featured_image: Maybe<CmsFeaturedImage>
}

export const defaultState = (): State => ({
  page: undefined,
  layouts: [],
  featured_image: null,
})

const state = defaultState

export default state
