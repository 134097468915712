import Vue from 'vue'
import { MutationTree } from 'vuex'
import { types } from './types'
import { State } from './state'
import { Availability, Event, Instance } from '@Core/@types/skyway'
import groupBy from 'lodash/groupBy'

const roomTags = [
  'dj rooms',
  'small rehearsal rooms',
  'project studio spaces',
  'large rehearsal rooms',
  'new media lab',
  'professional studio spaces',
]

const mutations: MutationTree<State> = {
  [types.SET_ROOMS](state: State, payload: Event[]): void {
    const groupedByTag = groupBy(payload, (item) => {
      if (item.tags[0] && roomTags.includes(item.tags[0].tag.toLowerCase())) {
        return item.tags[0].tag
      }
    })

    state.roomGroups = groupedByTag
    state.rooms = payload
  },
  [types.SET_SELECTED](
    state: State,
    payload: { zone: Availability; instance: Instance }
  ): void {
    if (!state.selection.includes(payload)) {
      state.selection.push(payload)
    } else {
      state.selection = state.selection.filter((i) => i !== payload)
    }
  },
  [types.CLEAR_SELECTION](state: State): void {
    state.selection = []
  },
}

export default mutations
