import { GetterTree } from 'vuex'
import { CmsComponent } from '@Core/@types/skyway'
import { RootState } from '@Core/store/types'
import { State } from './state'

const getters: GetterTree<State, RootState> = {
  optionComponentByName:
    (state: State) =>
    (componentName: string): CmsComponent | undefined => {
      const component =
        state.cmsComponents &&
        state.cmsComponents?.find(
          (component) => component.name === componentName
        )

      return component && component.data
    },
  allOptionComponentsByName:
    (state: State) =>
    (componentName: string): CmsComponent[] => {
      const components =
        state.cmsComponents &&
        state.cmsComponents?.filter(
          (component) => component.name === componentName
        )

      return components || []
    },
}

export default getters
