import { ActionTree, ActionContext } from 'vuex'
import { RootState } from '@Core/store/types'
import { State } from './state'
import { types } from './types'

const actions: ActionTree<State, RootState> = {
  async getRooms(
    context: ActionContext<State, RootState>,
    query: string = 'getRooms'
  ): Promise<Event[]> {
    const data = await this.$events.getEvents(query)

    context.commit(types.SET_ROOMS, data.getEvents)

    return data.getEvents
  },
}

export default actions
