import { CmsComponent } from '@Core/@types/skyway'

export interface ILayoutParameters {
  components: { data: any; name: string }[]
  id: string
  name: string | null
}

export function CMSNestedLayoutTransformer(input): ILayoutParameters {
  if (input) {
    const component: ILayoutParameters = Object.assign(
      {},
      {
        components: input.components || input.layout.components || [],
        id: input.id,
        name: input.name || input.layout.name || null,
      }
    )
    return component
  } else {
    return {
      components: [],
      id: '',
      name: null,
    }
  }
}

export function CmsComponentNameTransformer(components: CmsComponent[]) {
  for (const c of components) {
    if (c.name === 'TileCollection') {
      if (c.data && c.data.extra && c.data.extra.type === 'VenueDetails') {
        c.name = 'VenueDetails'
      }
    }
  }

  return components
}
