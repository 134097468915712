



















import { Component, Ref, Vue } from 'nuxt-property-decorator'
import Modal from '@UI/components/modals/Modal.vue'
import NewsletterSignupForm from '@Marketing/components/content/newsletter-signup/NewsletterSignupForm.vue'

@Component({
  components: {
    NewsletterSignupForm,
  },
})
export default class NewsletterSignup extends Vue {
  $refs!: {
    modal: Modal
  }

  @Ref() readonly modal!: Modal

  handleSuccess() {
    this.$refs.modal.close()
  }
}
