import { ActionTree, ActionContext } from 'vuex'
import { RootState } from '@Core/store/types'
import { CmsMenu } from '@Core/@types/skyway'

import { State } from './state'
import { types } from './types'

const actions: ActionTree<State, RootState> = {
  async getMenu(
    context: ActionContext<State, RootState>,
    slug: string
  ): Promise<CmsMenu | false> {
    try {
      const data = await this.$wp.getMenu(slug)

      context.commit(types.SET_MENU, data.getCMSMenu)

      return data.getCMSMenu
    } catch (err_) {
      return false
    }
  },
}

export default actions
