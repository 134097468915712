import { Package } from '@Core/@types/skyway'
import { MutationTree } from 'vuex'
import { types } from './types'
import { State } from './state'

const mutations: MutationTree<State> = {
  [types.SET_PACKAGES](state: State, payload: Package[]) {
    state.packages = payload
  },
  [types.SET_PACKAGE](state: State, payload: Package) {
    state.package = payload
  },
}

export default mutations
