import { MutationTree } from 'vuex'
import { CmsComponent } from '@Core/@types/skyway'
import { types } from './types'
import { State } from './state'

const mutations: MutationTree<State> = {
  [types.SET_OPTIONS_COMPONENTS](state: State, payload: CmsComponent[]): void {
    state.cmsComponents = payload
  },
}

export default mutations
