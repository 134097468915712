import Vue from 'vue'
import { MutationTree } from 'vuex'
import { types } from './types'
import { CmsArchive, CmsArchivePagination } from '@Core/@types/skyway'
import { State, defaultState } from './state'

const mutations: MutationTree<State> = {
  [types.SET_ARCHIVE](state: State, payload: CmsArchive): void {
    const type = payload.entries ? 'entries' : 'entries_extended'
    if (payload[type]) {
      for (let post of payload[type]) {
        if (!state.posts.find((p) => post && p.id === post.id)) {
          if (post) {
            state.posts.push(post)
          }
        }
      }
    }
  },
  [types.SET_PAGINATION](state: State, payload: CmsArchivePagination): void {
    const { current_page, total_entries, entries_per_page } = payload

    state.currentPage = current_page
      ? (current_page as number)
      : state.currentPage
    state.totalEntries = total_entries
      ? (total_entries as number)
      : state.totalEntries
    state.entriesPerPage = entries_per_page
      ? (entries_per_page as number)
      : state.entriesPerPage
  },
  [types.RESET](state: State): void {
    Object.assign(state, defaultState())
  },
}

export default mutations
