// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes loading{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}.text-editor h1,.text-editor h2,.text-editor h3,.text-editor h4,.text-editor h5,.text-editor h6{margin:1rem 0;letter-spacing:-.16px;color:#f7f6f3;font-size:1.0625rem;line-height:1.3125rem;text-transform:uppercase;font-weight:700;margin-bottom:.5rem;font-family:\"Work Sans\",sans-serif}@media only screen and (min-width:768px){.text-editor h1,.text-editor h2,.text-editor h3,.text-editor h4,.text-editor h5,.text-editor h6{font-size:1.25rem;line-height:1.5rem}}.text-editor blockquote{margin:1rem 1.5rem}.text-editor pre{font-family:\"Work Sans\",sans-serif}.text-editor a:not(.button),.text-editor li,.text-editor ol,.text-editor p,.text-editor pre{color:#b7b7b8}.text-editor p,.text-editor pre{margin:1rem 0}.text-editor ol>li,.text-editor ul>li{align-items:flex-start}.text-editor ul>li:before{margin-top:8px}.text-editor a.button{margin:1rem 1rem 1rem 0}.text-editor img{height:auto}.text-editor a,.text-editor strong{font-weight:600}.text-editor p:last-child{margin-bottom:0}.text-editor .layout-grid{grid-template-columns:minmax(0,1fr) minmax(0,1fr)}.text-editor .layout-grid .text--muted{color:#b7b7b8}.light-bg:has(.text-editor) *,.light-bg:has(.text-editor) a:not(.button),.light-bg:has(.text-editor) li,.light-bg:has(.text-editor) ol,.light-bg:has(.text-editor) p,.light-bg:has(.text-editor) pre{color:#000}.light-bg:has(.text-editor) ul{list-style-image:url(\"data:image/svg+xml;charset=utf-8,%3Csvg width='12' height='11' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.435 11.6L12 6.185 6.45.8 4.28 2.908l1.448 1.405c.15.146.044.395-.168.395l-5.558.004L0 7.695l5.54-.004c.212 0 .318.248.168.394L4.267 9.489l2.168 2.11v.001z' fill='%23db0000'/%3E%3C/svg%3E\")}.light-bg:has(.text-editor) ol li:before{color:#db0000}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black": "#000",
	"blackBg": "#121212",
	"white": "#fff",
	"offWhite": "#f7f6f3",
	"grey": "#b7b7b8",
	"darkGrey": "#474747",
	"mediumGrey": "#7d7d7d",
	"lightGrey": "#e0e0e0",
	"darkGrey60": "rgba(66,66,66,.6)",
	"lightGrey60": "hsla(0,0%,87.8%,.6)",
	"red": "#db0000",
	"violet": "#b8a4ea",
	"lime": "#d4fe01",
	"price1": "#e6ff66",
	"price2": "#b699ff",
	"price3": "#66ff91",
	"price4": "#f68383",
	"price5": "#80a4ff",
	"price6": "#ff99c0",
	"price7": "#00b893",
	"price8": "#ff9166",
	"screen375": "375px",
	"screen480": "480px",
	"screen640": "640px",
	"screen768": "768px",
	"screen1024": "1024px",
	"screen1200": "1200px",
	"screen1440": "1440px",
	"screen1560": "1560px"
};
module.exports = ___CSS_LOADER_EXPORT___;
