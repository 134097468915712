
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ShortCodeParser from '@Marketing/helpers/shortcodes'

/**
 * Wrapper to handle default wordpress output classes from the
 * WYSIWYG block
 */
@Component
export default class TextEditorBlock extends Vue {
  @Prop({ type: String, required: true }) content!: string

  get parsedContent() {
    return ShortCodeParser.parseContent(this.content)
  }

  render(h) {
    return h({
      template: `<div class="text-editor">${this.parsedContent}</div>`,
    })
  }
}
