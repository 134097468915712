import { CmsMenu } from '@Core/@types/skyway'

export interface State {
  menus: CmsMenu[]
  lastFetch: { [key: string]: number }
}

export const defaultState = (): State => ({
  menus: [],
  lastFetch: {},
})

const state = defaultState

export default state
