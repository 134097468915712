import { Pages } from '@Marketing/config/imports'

export const ROUTE_NAME = {
  INDEX: 'home',
  PING: 'ping',
  STORIES: 'stories',
  MEMBERSHIP: 'support-membership',
  MEMBERSHIP_SUB: 'support-membership-id',
  READ_WATCH_LISTEN: 'read-watch-listen',
  READ_WATCH_LISTEN_LANDING: 'read-watch-listen-landing',
  READ_WATCH_LISTEN_CHILD: 'read-watch-listen-child',
  READ_ARTICLES: 'read-articles',
  READ_ARTICLE_WITH_FORM: 'read-article-with-form',
  WATCH_ARTICLES: 'watch-articles',
  Listen_ARTICLES: 'listen-articles',
  ANNUAL_REVIEW: 'annual-review',
  MEMBERSHIP_INDEX: 'membership-index',
  CREATIVE_STUDIOS_MEMBERSHIP: 'creative-studios-membership-index',
  VENUE_HIRE: 'venue-hire',
  OUR_WORK: 'our-work',
  COMMUNITY: 'engagement-community',
  TEACHERS: 'engagement-teachers',
  NEWSLETTER_SIGN_UP: 'newsletter-sign-up',
  ALL: 'all',
}

export default [
  {
    path: '/',
    component: Pages.Index,
    name: ROUTE_NAME.INDEX,
  },
  {
    path: '/ping',
    component: Pages.PingPage,
    name: ROUTE_NAME.PING,
  },
  {
    path: '/read-watch-listen',
    component: Pages.ReadWatchListen,
    name: ROUTE_NAME.READ_WATCH_LISTEN,
    children: [
      {
        path: '/read-watch-listen',
        component: Pages.ReadWatchListenLanding,
        name: ROUTE_NAME.READ_WATCH_LISTEN_LANDING,
      },
      {
        path: '/read-watch-listen/*',
        alias: '/preview//read-watch-listen/*',
        component: Pages.ReadWatchListenChild,
        name: ROUTE_NAME.READ_WATCH_LISTEN_CHILD,
      },
    ],
  },
  {
    path: '/read/centre59',
    component: Pages.ReadPageWithForm,
    name: ROUTE_NAME.READ_ARTICLE_WITH_FORM,
  },
  {
    path: '/read/daniel-kaluuya-joins-the-roundhouse-as-associate-artistic-director',
    component: Pages.ReadPageWithForm,
    name: `${ROUTE_NAME.READ_ARTICLE_WITH_FORM}_1`,
  },
  {
    path: '/read/*',
    alias: '/preview/read/*',
    component: Pages.ReadArticles,
    name: ROUTE_NAME.READ_ARTICLES,
  },
  {
    path: '/watch/*',
    alias: '/preview/watch/*',
    component: Pages.WatchArticles,
    name: ROUTE_NAME.WATCH_ARTICLES,
  },
  {
    path: '/listen/*',
    alias: '/preview/listen/*',
    component: Pages.ListenArticles,
    name: ROUTE_NAME.LISTEN_ARTICLES,
  },
  {
    path: '/annual-review',
    alias: '/preview/annual-review',
    component: Pages.AnnualReviewPage,
    name: ROUTE_NAME.ANNUAL_REVIEW,
  },
  {
    path: '/support-us/membership',
    component: Pages.MembershipIndexPage,
    name: ROUTE_NAME.MEMBERSHIP_INDEX,
  },
  {
    path: '/venue-hire',
    component: Pages.VenueHirePage,
    name: ROUTE_NAME.VENUE_HIRE,
  },
  {
    path: '/our-work',
    component: Pages.OurWorkPage,
    name: ROUTE_NAME.OUR_WORK,
  },
  {
    path: '/young-creatives-11-30/creative-studios-membership',
    component: Pages.CreativeStudioMembershipPage,
    name: ROUTE_NAME.CREATIVE_STUDIOS_MEMBERSHIP,
  },
  {
    path: '/young-creatives-11-30/community/roundhouse-community-network',
    component: Pages.CommunityEngagementPage,
    name: ROUTE_NAME.COMMUNITY,
  },
  {
    path: '/young-creatives-11-30/education/roundhouse-teachers-network',
    component: Pages.CommunityEngagementPage,
    name: ROUTE_NAME.TEACHERS,
  },
  {
    path: '/sign-up',
    component: Pages.NewsletterSignupPage,
    name: ROUTE_NAME.NEWSLETTER_SIGN_UP,
  },
  {
    path: '/*',
    alias: '/preview/*',
    component: Pages.All,
    name: ROUTE_NAME.ALL,
  },
]
