import { Availability, Event, Instance } from '@Core/@types/skyway'

type SlotSelection = {
  zone: Availability
  instance: Instance
}

export interface State {
  roomGroups: { [key: number]: Event[] }
  rooms: Event[]
  selection: SlotSelection[]
}

const state = (): State => ({
  roomGroups: [],
  rooms: [],
  selection: [],
})

export default state
