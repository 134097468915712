export class ShortcodeParser {
  protected voidTags: string[] = [
    'p',
    'div',
    'header',
    'footer',
    'ol',
    'ul',
    'li',
    'a',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'code',
    'pre',
    'img',
    'script',
    'link',
    'figure',
    'figcaption',
    'article',
    'section',
  ]
  protected shortcodes: any

  constructor(Shortcodes) {
    this.shortcodes = Shortcodes
  }

  parseContent(content: string): string {
    const trimmed: string = content.trim()
    const splitContent: string[] = trimmed.split('\n')
    let isCodeTag: boolean = false
    let check: string = ''

    const parsedContent = splitContent.reduce((content: string[], line) => {
      // check if line is a shortcode
      let clone: string = line
      if (!isCodeTag) {
        check = clone.replace('<p>', '')
        if (clone && check[0] === '[') {
          const match = clone.match(/([[])\w+/g)
          const shortcode = match ? match.toString().substr(1) : ''

          if (Object.keys(this.shortcodes).indexOf(shortcode) >= 0) {
            if (this.shortcodes[shortcode]) {
              line = this.shortcodes[shortcode](line)
            } else {
              line = ''
            }
          }
        } else {
          // wrap lines without voidTags in paragraph tags
          let match = line.trim().match(/^<\w+/g)
          let tag = match && match[0].trim().slice(1)

          if (tag === 'code') {
            isCodeTag = true
          } else if (
            tag &&
            this.voidTags.indexOf(tag) === -1 &&
            line.length > 1
          ) {
            line = '<p>' + line + '</p>'
          }
        }
      }

      content.push(line)
      return content
    }, [])
    return parsedContent.join('')
  }
}
