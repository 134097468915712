import { GetterTree } from 'vuex'
import { State } from './state'
import { RootState } from '@Core/store/types'
import { CmsArchive } from '@Core/@types/skyway'

const getters: GetterTree<State, RootState> = {
  latestPostsByScreensize:
    (state: State) =>
    (amountToReturn: number): CmsArchive[] => {
      return state.posts.slice(0, amountToReturn)
    },
}

export default getters
