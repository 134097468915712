import { MutationTree } from 'vuex'
import { CmsLayout, CmsPage } from '@Core/@types/skyway'
import { types } from './types'
import { State, defaultState } from './state'
import { CmsComponentNameTransformer } from '@Marketing/transformers/layout'

const layoutTransformer = (layouts: CmsLayout[]) => {
  for (const l of layouts) {
    l.components = CmsComponentNameTransformer(l.components || [])
  }

  return layouts
}

const mutations: MutationTree<State> = {
  [types.SET_PAGE](state: State, payload: CmsPage): void {
    if (payload && payload.id) {
      state.page = payload

      state.featured_image = payload.featured_image || null

      state.layouts = layoutTransformer(payload.layouts as CmsLayout[]) || []
    }
  },
  [types.RESET](state: State): void {
    Object.assign(state, defaultState())
  },
}

export default mutations
