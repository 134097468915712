






















































import { Component, Vue, namespace } from 'nuxt-property-decorator'
import TextField from '@UI/components/forms/fields/TextField.vue'
import CheckboxField from '@UI/components/forms/fields/CheckboxField.vue'
import { NewsletterInput } from '@Core/@types/skyway'

const customer = namespace('customer')

@Component({
  components: {
    TextField,
    CheckboxField,
  },
})
export default class NewsletterSignupForm extends Vue {
  @customer.Action
  newsletterSignup

  @customer.State
  signed_up

  @customer.Mutation('SET_SIGNED_UP')
  setSignedUp!: (state: boolean) => void

  public customerDetails: NewsletterInput = {
    first_name: '',
    last_name: '',
    email: '',
  }

  public preferences: number[] = []

  get preferencePayload() {
    return (
      this.preferences?.map((p) => {
        return {
          type_ref: p,
        }
      }) || []
    )
  }

  public preferenceOptions = [
    {
      label: 'General mailing list',
      description:
        'I would like to hear about gigs, events, news and relevant special offers',
      image: '/images/mailing-list-1.jpg',
      type_ref: '1',
    },
    {
      label: 'Youth mailing list',
      description:
        "I would like to hear about Roundhouse's news and opportunities for people aged 11 -30",
      image: '/images/mailing-list-2.jpg',
      type_ref: '2',
    },
  ]

  async signup() {
    try {
      const payload = {
        ...this.customerDetails,
        preferences: this.preferencePayload,
      }
      const result = await this.newsletterSignup({
        newsletterInput: payload,
        recaptcha: await this.$recaptcha.execute('newsletter'),
      })
      this.setSignedUp(Boolean(result))
      this.$emit('success')
      this.$eventBus.notifySuccess(
        'Thank you for signing up to our newsletter!'
      )
    } catch (err_) {
      console.log(err_)
    }
  }

  beforeDestroy() {
    try {
      this.$recaptcha.destroy()
    } catch (err_) {
      this.$logger.captureException(err_, {
        tags: {
          recaptcha: '[Recaptcha destroy exception caught on newsletter]',
        },
      })
    }
  }
}
