import { GetterTree } from 'vuex'
import { State } from './state'
import { RootState } from '@Core/store/types'
import { CmsMenu } from '@Core/@types/skyway'

const getters: GetterTree<State, RootState> = {
  menuBySlug:
    (state: State) =>
    (slug: string): CmsMenu | undefined => {
      const menu =
        state.menus && state.menus.find((menu) => menu && menu.slug === slug)

      return menu
    },
}

export default getters
