import { ActionTree, ActionContext } from 'vuex'
import { RootState } from '@Core/store/types'
import { CmsPage } from '@Core/@types/skyway'

import { State } from './state'
import { types } from './types'

const actions: ActionTree<State, RootState> = {
  async getPage(
    context: ActionContext<State, RootState>,
    slug: string
  ): Promise<CmsPage | false> {
    const data = await this.$wp.getPage(slug)

    if (data && data.getCMSPage) {
      context.commit(types.SET_PAGE, data.getCMSPage)

      return data.getCMSPage
    }

    return false
  },
  resetPage(context: ActionContext<State, RootState>) {
    context.commit(types.RESET)
  },
}

export default actions
